<script setup>
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  isEmptyValue: {
    type: Boolean,
    default: false,
  },
  isErrorValue: {
    type: Boolean,
    default: false,
  },
  isCustomModal: {
    type: Boolean,
    default: false,
  },
  appearance: {
    type: String,
    default: 'warning',
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
  denyButtonText: {
    type: String,
  },
  confirmButtonText: {
    type: String,
  },
  denyButtonAppearance: {
    type: String,
    default: 'secondary',
  },
  confirmButtonAppearance: {
    type: String,
    default: 'primary',
  },
  customStyle: {
    type: String,
    default: 'width: 418px; padding: 4px 8px; overflow: visible;',
  },
})

const emit = defineEmits(['confirm', 'deny', 'close'])
const disabledButton = ref(false)
const disabledButtonConfirm = ref(false)
const disabledCustomeButton = ref(true)
const disabledCustomeButtonConfirm = ref(true)

watch(
  () => props.showModal,
  () => {
    disabledButton.value = false
  },
)

watch(
  () => props.isEmptyValue,
  (val) => {
    disabledButtonConfirm.value = val
  },
)

watch(
  () => props.isErrorValue,
  (val) => {
    disabledCustomeButton.value = val
    disabledCustomeButtonConfirm.value = val
  },
)

function handleClick(action) {
  disabledButton.value = true
  emit(action)
}
</script>

<template>
  <KModal :show-modal="showModal" :style="customStyle">
    <template #title>
      <div v-if="isCustomModal">
        <div class="w-full flex justify-start !absolute top-0 mt-3">
          <div class="text-xl font-semibold text-[#2A2A2A]">
            {{ title }}
          </div>
        </div>
        <div class="w-full border-b border-[#D1D1D1] mb-6" />
        <button type="button" class="!absolute top-2 right-5 p-0" @click="handleClick('close')">
          <Icon name="mdi:close" size="24" />
        </button>
      </div>
      <div v-else>
        <h1 class="text-lg font-semibold tracking-[0.02px]">
          {{ title }}
        </h1>
      </div>
    </template>
    <template #message>
      <p class="text-xs mb-4">
        {{ message }}
      </p>
      <slot />
      <slot name="modalForm" />
    </template>
    <template #buttons>
      <div v-if="isCustomModal" class="flex gap-2 w-full justify-end">
        <KButton
          v-if="denyButtonText"
          :class="{ 'w-1/2': confirmButtonText, 'w-1/2': !confirmButtonText, 'danger bg-danger': denyButtonAppearance === 'danger' }" :appearance="denyButtonAppearance" :disabled="disabledCustomeButton" @click="handleClick('deny')"
        >
          {{ denyButtonText }}
        </KButton>
        <slot name="btnConfirm">
          <KButton
            v-if="confirmButtonText"
            :class="{ 'w-1/2': denyButtonText, 'w-1/2': !denyButtonText, 'danger bg-danger': confirmButtonAppearance === 'danger' }" :appearance="confirmButtonAppearance" :disabled="disabledCustomeButton || disabledCustomeButtonConfirm" @click="handleClick('confirm')"
          >
            {{ confirmButtonText }}
          </KButton>
        </slot>
      </div>
      <div v-else class="flex gap-2 w-full">
        <KButton
          v-if="denyButtonText"
          :class="{ 'w-1/2': confirmButtonText, 'w-full': !confirmButtonText, 'danger bg-danger': denyButtonAppearance === 'danger' }" :appearance="denyButtonAppearance" :disabled="disabledButton" @click="handleClick('deny')"
        >
          {{ denyButtonText }}
        </KButton>
        <slot name="btnConfirm">
          <KButton
            v-if="confirmButtonText"
            :class="{ 'w-1/2': denyButtonText, 'w-full': !denyButtonText, 'danger bg-danger': confirmButtonAppearance === 'danger' }" :appearance="confirmButtonAppearance" :disabled="disabledButton || disabledButtonConfirm" @click="handleClick('confirm')"
          >
            {{ confirmButtonText }}
          </KButton>
        </slot>
      </div>
    </template>
  </KModal>
</template>

<style scoped>
.danger{
  @apply text-white
}

.bg-danger{
  @apply bg-[#B22A09]
}

.bg-danger:hover{
  @apply bg-[#D81600]
}
</style>

<style>
#alasan-pembatalan-menu {
  position: fixed;
  max-width: 370px;
}
</style>
